import Utility from "../../../shared/utility";
import AddFavourite from "../../utility/js/addFav";
import EncryptUtility from "../../utility/js/encrypt-utility";
import breadcrumbComp from "../../common/breadcrumb-comp";
import menuComp from "../app-secure-menu-comp.vue";
export default {
  data() {
    return {
      menuData: [],
      userId: EncryptUtility.localStorageDecrypt("userID"),
      menuDetails: [],
      modaldialog: false,
      mainPage: null,
      pageUrl: null,
      subPage: null,
      showAdd: false,
      backSlash: true,
      searchValue: "",
      headers: [
        {
          text: "System Name",
          align: "start",
          value: "SystemName",
          class: "primary customwhite--text",
        },
        {
          text: "Friendly Name",
          value: "FriendlyName",
          class: "primary customwhite--text",
        },
        {
          text: "Secured",
          value: "issecure",
          class: "primary customwhite--text",
        },
        {
          text: "Actions",
          value: "action",
          class: "primary customwhite--text",
        },
      ],
      menuList: [],
      isActive: true,
      compCalled: false,
      secured: true,
      editedItemFriendlyName: "",
      editedItem: null,
      editedItemMenuId: 0,
      editedItemIsSecure: false,
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.mainPage = data.mainPage;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  mounted() {
    this.getMenuList();
    if (
      EncryptUtility.localStorageDecrypt("searchStatus") !== null &&
      EncryptUtility.localStorageDecrypt("searchStatus") !== undefined
    ) {
      this.searchValue = EncryptUtility.localStorageDecrypt("searchStatus");
      this.isActive = this.searchValue == "true" ? true : false;
    }
    if (
      EncryptUtility.localStorageDecrypt("searchRole") !== null &&
      EncryptUtility.localStorageDecrypt("searchRole") !== undefined
    ) {
      this.menuList = EncryptUtility.localStorageDecrypt("searchRole");
    }
  },
  methods: {
    //Close add menu dialog
    close() {
      this.compCalled = false;
      this.getMenuList();
    },
    //On Click of Favorite icon
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //Reset the screen
    resetFunction() {
      this.isActive = true;
      this.menuList = "";
      EncryptUtility.localStorageRemoveItem("searchStatus");
      EncryptUtility.localStorageRemoveItem("searchRole");
      this.getMenuList();
    },
    //Get Menu List
    getMenuList() {
      this.menuData = [];
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let searchObject = {
        pass: 1,
        userid: this.userId,
        menu: 0,
      };
      this.axios
        .post("/ap/secure_menu", searchObject)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            this.menuData = responseData;
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Edit Menu
    editItem(item) {
      this.editedItem = item;
      this.editedItemFriendlyName = item.FriendlyName;
      this.editedItemMenuId = item.menuid;
      this.editedItemIsSecure = item.issecure;
      this.modaldialog = true;
    },
    //Update Menu
    updateMenu() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let searchObject = {
        pass: 3,
        userid: this.userId,
        menuid: this.editedItemMenuId,
      };

      this.axios
        .post("/ap/secure_menu", searchObject)
        .then((response) => {
          if (response.status == 200) {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "success",
              isShow: true,
              message: response.data.message,
            };
            this.closeDialog();
            this.getMenuList();
            this.$store.commit("ConfigModule/Alert", Alert);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Close dialog popup
    closeDialog() {
      this.modaldialog = false;
    },
    //Method for searching for menus with search bar
    searchMenu() {
      EncryptUtility.localStorageEncrypt("searchRole", this.menuList);
    },
  },
  components: {
    breadcrumbComp,
    menuComp,
  },
};
