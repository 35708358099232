import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/js/encrypt-utility";
export default {
  name: "menuComp",
  props: {
    compCalled: Boolean,
    selectedMenu: String,
    userId: String,
    secured: Boolean,
  },
  data: () => ({
    installId: EncryptUtility.localStorageDecrypt("installId"),
    menuRule: [(v) => !!v || "Field is required"],
    selectedMenu: "",
    secured: true,
    menuDataList: []
  }),
  mounted() {
    this.getMenuList();
  },
  methods: {
    //Get menus to fill table
    getMenuList() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let searchObject = {
        pass: 0,
        userid: this.userId,
        menu: 0, 
      };
      this.axios
        .post("/ap/secure_menu", searchObject)
        .then((response) => {
          if (response.status == 200) {
            
            this.menuDataList = JSON.parse(response.data.body.message);
            LoaderDialog.visible = false;

            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Add Menu Information
    onClickSave() {
      if (this.$refs.menuAddForm.validate()) {
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        let menuData = {
          pass: 2,
          userid: this.userId,
          menuid: this.selectedMenu
        };
        {
          this.axios
            .post("/ap/secure_menu", menuData)
            .then((response) => {
              if (response.status == 200 || response.status == 204) {
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                let Alert = {
                  type: "success",
                  isShow: true,
                  message: response.data.message,
                };
                this.closeDialog();
                this.$store.commit("ConfigModule/Alert", Alert);
              } else {
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                let Alert = {
                  type: "error",
                  isShow: true,
                  message: response.data.message,
                };
                this.$store.commit("ConfigModule/Alert", Alert);
              }
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            })
            .catch((error) => {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: Utility.apiError(error),
              };
              this.$store.commit("ConfigModule/Alert", Alert);
              this.errorMessage = error.message;
            });
        }
      }
    },
    //Close Dialog
    closeDialog() {
      this.compCalled = false;
      this.$emit("hide");
      this.selectedMenu = "";
      this.secured = false;
    },
  },
};